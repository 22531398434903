import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

import "./App.css"
import "./CssFiles/CommonStyles/CommonStyles.css"
import "./CssFiles/CustomStyles/CustomStyles.css"
import "./CssFiles/Responsiveness/Responsiveness.css"
import "bootstrap/dist/css/bootstrap.min.css"

import OpeningWall from "./Pages/Wall/OpeningWall"
import CommitmentWall from "./Pages/Wall/CommitmentWall"

function App() {
  return (
    <div className="page">
      <Router>
        <Routes>
          <Route path="/" element={<OpeningWall />} />
          <Route path="/commitment-wall" element={<CommitmentWall />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App
