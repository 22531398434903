import React, { useState, useEffect } from "react"
import { Col, Row, Image } from "antd"
import HeaderImage from "../../Assets/Images/Approved Logo-YBM 2025.png"
import { getRequest } from "../../Utils/api/ApiRequests"

const CommitmentWall = () => {
  const [messages, setMessages] = useState([])

  async function fetchCommitments() {
    const response = await getRequest(`commitments/get`)
    setMessages(response.data.data)
  }

  useEffect(() => {
    // Initial fetch
    fetchCommitments()

    const interval = setInterval(async () => {
      const response = await fetchCommitments()
      if (response?.status === 200) {
        clearInterval(interval)
      }
    }, 2000)

    return () => clearInterval(interval) // Clean up interval on component unmount
  }, [])

  return (
    <div className="bg-wall">
      <Row>
        <Col span={24} className="center my-2">
          <Image
            src={HeaderImage}
            alt="login-image"
            preview={false}
            className="logo"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} className="header-text" align="center">
          WHAT WILL YOU DARE TO DO ON OUR 2025 JOURNEY?
        </Col>
      </Row>
      <Row className="wall-cont">
        <Col xs={24} md={22} className="chat-messages p-4 center mx-auto">
          <div className="_container">
            {messages?.map((message, index) => (
              <div
                key={index}
                className="chat-message mb-4"
                style={{
                  color: "white",
                  backgroundColor: index % 2 === 0 ? "#2A02B7" : "#008CF6", // Alternate colors
                  border: "solid 7px rgb(131, 232, 237)",
                  borderRadius: "25px",
                  boxShadow:
                    "0 0 15px 5px rgba(131, 232, 237, 0.8), 0 0 30px 10px rgba(131, 232, 237, 0.5)",
                  padding: "10px",
                  textAlign: "center",
                  height: "fit-content",
                }}
              >
                {message.message}
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default CommitmentWall
