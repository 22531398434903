import axios from "axios"
import { getIpAddress, getUser } from "../Common"

const BASE_URL = process.env.REACT_APP_BASE_URL
const API_KEY = process.env.REACT_APP_API_KEY

// Set up a global response interceptor for handling 401 errors
// axios.interceptors.response.use(
//   (response) => {
//     return response
//   },
//   async (error) => {
//     // Handle 401 error (unauthorized)
//     if (error.response && error.response.status === 401) {
//       localStorage.clear()
//       showToast("error", "Session expired. Logging out now...")
//       setTimeout(() => {
//         window.location.href = "/" // Redirect to the login page after 1 second
//       }, 1000)
//     }
//     return Promise.reject(error)
//   }
// )

// Post request function
export const postRequest = async (apiName, data = {}, isFormData = false) => {
  const url = `${BASE_URL}/${apiName}`

  const headers = {
    "Content-Type": isFormData ? "multipart/form-data" : "application/json",
    "API-KEY": API_KEY,
  }
  const payload = {
    ...data,
    "api-key": API_KEY,
    ip_address: getIpAddress(),
    requester: getUser(),
  }

  try {
    const response = await axios.post(url, payload, { headers })
    return { success: true, data: response.data }
  } catch (error) {
    return error
  }
}

// Get request function
export const getRequest = async (apiName, params = {}) => {
  const url = `${BASE_URL}/${apiName}`
  const headers = {
    "Content-Type": "application/json",
    "API-KEY": process.env.REACT_APP_API_KEY, // Assuming you're storing the API key in an environment variable
  }
  const payload = {
    ...params,
    // token: getToken(),
    requester: getUser(),
  }
  try {
    const response = await axios.get(url, { params: payload, headers: headers })
    return { status: 200, data: response.data }
  } catch (error) {
    return error
  }
}
