import React, {
  useEffect,
  useState,
  // useEffect,
} from "react"

import { Col, Row, Image, Input, Button, Space, Modal, message } from "antd"
import HeaderImage from "../../Assets/Images/Approved Logo-YBM 2025.png"
import { postRequest } from "../../Utils/api/ApiRequests"
import { fetchIp, getIpAddress } from "../../Utils/Common"

// import { UseGetRequest } from '../../Utils/api/ApiRequests'
// import { usePostRequest } from '../../hooks/usePostRequest'

const { TextArea } = Input

const OpeningWall = () => {
  const [textAreaData, setTextAreaData] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [toogle, setToogle] = useState(false)

  const handleText = (e) => {
    setTextAreaData(e.target.value)
  }

  async function saveCommitment() {
    setIsLoading(true)
    const response = await postRequest(`commitments/create`, {
      message: textAreaData,
      ip_address: getIpAddress(),
    })
    if (response.success) {
      setToogle(true)
    } else {
      message.error(response?.response?.data?.messages?.error_messages[0])
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchIp()
  }, [])

  return (
    <div className="bg-wall">
      <Row>
        <Col span={24} className="center my-5">
          <Image
            src={HeaderImage}
            alt="login-image"
            preview={false}
            className="logo"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} className="header-text" align="center">
          <p className="page-title">
            WHAT WILL YOU DARE TO DO ON OUR 2025 JOURNEY?
          </p>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="header-text my-4" align="center">
          <div className="area-border">
            <TextArea
              rows={8}
              className="header-textarea"
              placeholder="WRITE HERE"
              maxLength={160}
              value={textAreaData}
              onChange={(e) => handleText(e)}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="header-text" align="center">
          <Space>
            <Button className="header-btn" onClick={() => setTextAreaData("")}>
              CANCEL
            </Button>
            <Button
              loading={isLoading}
              className="header-btn"
              onClick={() => saveCommitment()}
            >
              SUBMIT
            </Button>
          </Space>
        </Col>
      </Row>
      <Modal
        open={toogle}
        width={390}
        centered
        onCancel={() => setToogle(false)}
        onClose={() => setToogle(false)}
        footer={null}
        closeIcon={null}
        className="modal-radius"
        maskClosable={false}
      >
        <div className="modal-style">
          <div>
            <Image src={HeaderImage} alt="login-image" preview={false} />
          </div>
          <span className="modal-title">
            THANK YOU, KA-PFIZER, FOR YOUR COMMITMENT!
          </span>
          <Button
            className="mt-3 mx-auto"
            onClick={() => {
              setToogle(false)
              setTextAreaData("")
            }}
          >
            YEY!
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default OpeningWall
